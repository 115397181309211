<template>
  <div>

  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    computed: {
    },
    beforeMount() {
    },
    mounted() {
    },
    methods: {
    }
  }
</script>