<template>
  <div>

    
    <div class="grid grid-cols-2 mb-4">
      <div class="col-span-1">
        <div class="flex flex-wrap items-center">
          <h2 class="text-2xl font-bold">Expense Policies</h2>
          <router-link :to="{ name: 'expense-policies-new' }" class="text-sm text-gray-700 ml-3">+ New Policy</router-link>
        </div>
      </div>
      <div class="col-span-1">
        <div class="search-input">
          <input
            type="search"
            name="search"
            id="search"
            class="placeholder-blue-200"
            placeholder="Search for staff name, role, etc."
            v-model="searchQuery">
          <ion-icon name="search-outline" class="search-input-icon" style="top: 50%; transform: translateY(-50%);"></ion-icon>
        </div>
      </div>
    </div>

    <template v-if="policies.loading">
      <sm-loader class="mb-10" />
    </template>
    <template v-else-if="policies.data.length">
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4">

        <template v-for="(policy, n) in policies.data">
          <div
            class="col-span-1 border border-blue-200 rounded px-8 pt-6 pb-6 cursor-pointer hover:shadow-lg"
            @click="selectPolicy(policy)"
            :key="n"
          >
            <div class="text-sm mb-3 font-bold">Policy {{ n+1 }}</div>
            <div class="mb-3">
              <div class="border-b border-gray-200 pb-3">
                <div class="text-sm text-gray-500 mb-1">From</div>
                <div class="flex items-start">
                  <ion-icon name="caret-down-circle-outline" class="text-xl mr-3" style="flex: 0 0 20px;" />
                  <div class="text-sm text-gray-700 break-all">{{ getRange(policy)[0] | currency }}</div>
                </div>
              </div>
              <div class="border-b border-gray-200 py-3">
                <div class="text-sm text-gray-500 mb-1">To</div>
                <div class="flex items-start">
                  <ion-icon name="caret-up-circle-outline" class="text-xl mr-3" style="flex: 0 0 20px;" />
                  <div class="text-sm text-gray-700 break-all">{{ getRange(policy)[1] | currency }}</div>
                </div>
              </div>
              <div class="border-b border-gray-200 py-3">
                <div class="text-sm text-gray-500 mb-1">Roles</div>
                <div class="flex items-start">
                  <ion-icon name="person-circle-outline" class="text-xl mr-3" style="flex: 0 0 20px;" />
                  <template v-if="policy.roles && policy.roles.length">
                    <div class="text-sm text-gray-600">
                      {{ policy.roles.map(role => role.name).join(', ') }}
                    </div>
                  </template>
                  <template v-else>
                    <div class="text-sm text-gray-600">There are no roles attached yet</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="text-xs text-gray-500">
              Last Updated: {{ policy.updated_at || policy.created_at | dateFormat('D, M dd Y h:ia') }}
            </div>
          </div>
        </template>

      </div>
    </template>
    <template v-else>
      <div class="text-sm">There are no policies available.</div>
    </template>

    <modal className="w-full md:w-1/2 lg:w-7/10 xl:w-5/10" ref="modal">

      <div v-if="selectedPolicy">

        <h1 class="text-2xl font-bold mb-10">Review Policy</h1>

        <div class="mb-10">
          <div class="grid grid-cols-1 md:grid-cols-2 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Lowest Amount</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="caret-down-circle-outline" class="text-lg mr-2"></ion-icon>
                {{ getRange(selectedPolicy)[0] | currency }}
              </div>
            </div>
            <div class="col-span-1 py-4 pl-4 -ml-4 border-t md:border-t-0 md:border-l border-blue-200">
              <div class="opacity-75 text-sm font-medium mb-1">Highest Amount</div>
              <div class="text-gray-500 text-sm flex flex-row items-center">
                <ion-icon name="caret-up-circle-outline" class="text-lg mr-2"></ion-icon>
                {{ getRange(selectedPolicy)[1] | currency }}
              </div>
            </div>
          </div>

          <div class="grid grid-cols-1 border-b border-blue-200">
            <div class="col-span-1 py-4">
              <div class="opacity-75 text-sm font-medium mb-1">Roles</div>
              <div class="text-gray-500 text-sm flex flex-row items-start">
                <ion-icon name="person-circle-outline" class="text-lg mr-2"></ion-icon>
                <template v-if="selectedPolicy.roles && selectedPolicy.roles.length">
                  <div class="w-full">
                    <template v-for="(role, n) in selectedPolicy.roles">
                      <div class="py-3" :class="{ 'border-b border-gray-200 pt-0': n < selectedPolicy.roles.length - 1 }" :key="n">
                        <div class="mb-2">{{ role.name }}</div>
                        <div>
                          Users: 
                          <template v-for="(user, i) in role.users">
                            <router-link :to="{ name: 'staff', params: { staffId: user.id } }" class="text-blue-700" :key="i">{{ user.name }}</router-link>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <div>There are no roles attached yet</div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-5/10">
          <div class="mb-10">
            <div class="font-medium mb-10 text-blue-700 cursor-pointer" @click="allow(roleForm)">
              Attach a role to this policy
            </div>

            <form @submit.prevent="attach" v-if="roleForm.allowed">

              <template v-if="getFormError(roleForm)">
                <div class="alert alert-red-soft mb-10">
                  <div class="alert-icon">!</div>
                  <span>{{ getFormError(roleForm) }}</span>
                </div>
              </template>

              <form-group
                type="select"
                :options="roles"
                left-icon="person-circle-outline"
                name="role"
                :form="roleForm"
                v-model="roleForm.data.role.value"
              >
                Role
              </form-group>

              <button type="submit" class="btn btn-blue" :disabled="isLoading">
                <span v-if="roleForm.loading">Attaching</span>
                <span v-else>Attach Role</span>
              </button>
            </form>
            
          </div>

          <div class="mb-10">
            <div class="font-medium mb-10 text-blue-700 cursor-pointer" @click="allow(form)">
              Change the range of this policy
            </div>

            <form @submit.prevent="submit" v-if="form.allowed">

              <template v-if="getFormError(form)">
                <div class="alert alert-red-soft mb-10">
                  <div class="alert-icon">!</div>
                  <span>{{ getFormError(form) }}</span>
                </div>
              </template>

              <form-group
                type="money"
                left-icon="caret-down-circle-outline"
                name="low"
                :form="form"
                v-model="form.data.low.value"
              >
                Lowest Amount
              </form-group>

              <form-group
                type="money"
                left-icon="caret-up-circle-outline"
                name="high"
                :form="form"
                v-model="form.data.high.value"
              >
                Highest Amount
              </form-group>

              <button type="submit" class="btn btn-blue" :disabled="isLoading">
                <span v-if="form.loading">Updateing</span>
                <span v-else>Update Policy</span>
              </button>
            </form>
            
          </div>
        </div>

        <template v-if="getFormError(deleteForm)">
          <div class="alert alert-red-soft mb-10">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(deleteForm) }}</span>
          </div>
        </template>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-red mr-3"
            @click.prevent="deletePolicy"
            :disabled="isLoading || 1"
          >
            <span v-if="deleteForm.loading">Deleting</span>
            <span v-else>Delete Policy</span>
          </button>
          <button
            type="button"
            class="btn btn-blue"
            @click.prevent="$refs.modal.close"
            :disabled="isLoading"
          >
            Close
          </button>
        </div>

      </div>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10" ref="approvalConfirmationModal">

      <h1 class="text-2xl font-bold mb-10">Enter Credit Limit</h1>

      <form @submit.prevent="approve" v-if="selectedPolicy">

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <div class="alert-icon">!</div>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="text-center">
          <button type="submit" class="btn btn-md btn-blue" :disabled="form.loading">
            <span v-if="form.loading">Approving</span>
            <span v-else>Approve Credit</span>
          </button>
        </div>

      </form>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="updateSuccessModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Expense Policy Updated
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.updateSuccessModal.close">
        Okay
      </button>
      
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="deleteSuccessModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Expense Policy Deleted
      </div>
      <!-- <div class="text-xs mb-10">
        The user
      </div> -->

      <button type="button" class="btn btn-blue" @click.prevent="$refs.deleteSuccessModal.close">
        Okay
      </button>
      
    </modal>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        selectedPolicy: null,
        searchQuery: '',
        roles: [
          {title: 'Super Admin', value: 'company_super'},
          {title: 'HR', value: 'company_hr'},
          {title: 'Finance Manager', value: 'company_finance'},
          {title: 'Audit', value: 'company_audit'},
          {title: 'Department Head', value: 'company_department_head'},
          {title: 'Unit Head', value: 'company_unit_head'},
          // {title: 'Employee', value: 'company_staff'},
        ],
        deleteForm: this.$options.resource(null),
        form: this.$options.basicForm([
          {name: 'low', value: '0', rules: 'nullable|min:0'}, {name: 'high', value: '100000'}
        ], { allowed: false }),
        roleForm: this.$options.basicForm([
          'role'
        ], { allowed: false }),
      }
    },
    computed: {
      policies() {
        return {
          ...this.resources.policies,
          columns: [
            {
              name: 'range',
              th: 'Approval Margin (₦)',
            }
          ],
          actions: [
            {
              text: 'Edit',
              class: 'btn btn-blue',
              action: () => {}
            }
          ]
        }
      },
      isLoading() {
        return this.form.loading || this.roleForm.loading || this.deleteForm.loading
      }
    },
    beforeMount() {
      this.getPolicies(false);
    },
    mounted() {
    },
    methods: {
      selectPolicy(policy) {
        this.selectedPolicy = policy;
        this.$refs.modal.open();
        this.form.data.low.value = `${this.getRange(policy)[0]}`;
        this.form.data.high.value = `${this.getRange(policy)[1]}`;
      },
      getRange(policy) {
        return policy.range?.split(' - ');
      },
      allow(form) {
        form.allowed = !form.allowed;
      },
      async getPolicies(forced) {
        this.loadResource('policies', forced);
      },
      async deletePolicy() {
        this.deleteForm.loading = true;

        await this.sendRequest('corporate.expensePolicies.delete', {
          success: () => {
            this.$refs.deleteSuccessModal.open();

            this.getPolicies(true);
          },
          error: error => {
            this.deleteForm.error = error;
          }
        });

        this.deleteForm.loading = false;
      },
      async attach() {
        if (!this.validateForm(this.roleForm)) {
          return false;
        }
        this.roleForm.loading = true;
        this.roleForm.error = null;

        await this.sendRequest('corporate.expensePolicies.attach', {
          data: {
            role_id: this.roleForm.data.role.value,
            policies: [this.selectedPolicy.id]
          },
          success: () => {
            this.$refs.updateSuccessModal.open();
            this.$refs.modal.close();

            this.roleForm = this.resetForm(this.roleForm);
            this.getPolicies(true);
          },
          error: error => {
            this.roleForm.error = error;
          }
        });

        this.roleForm.loading = false;
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.loading = true;
        this.form.error = null;

        await this.sendRequest('corporate.expensePolicies.update', {
          data: {
            expense_policy_id: this.selectedPolicy.id,
            range: `${this.form.data.low.value || 0} - ${this.form.data.high.value}`
          },
          success: () => {
            this.$refs.updateSuccessModal.open();
            this.$refs.modal.close();
            this.form = this.resetForm(this.form);
            this.getPolicies(true);
          },
          error: error => {
            this.form.error = error;
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>